import React from 'react';
import { Added, Improved, Link, PageWithSubNav, Paragraph, PlatformHeader } from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="April 2023" subnav="release-notes">
      <div id="April2023" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">Check back throughout the month for more updates!</Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Added>
                Links to Version 5 API docs to the{' '}
                <Link href="/components/forms/select/code">Select/Creatable Select</Link> and{' '}
                <Link href="/components/tooltip/code">Tooltip</Link> components.
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.30.0"
              versionUrl="https://github.com/hudl/hudl-frontends/pkgs/npm/uniform-ui-components"
            />
            <div className={styles.changesList}>
              <Improved>
                Module{' '}
                <Link href="/components/modules/design#Choosing%20a%20Visualization">
                  data visualization components
                </Link>{' '}
                can now be de-selected if they were previously selected, then became empty.
              </Improved>
            </div>
          </div>
          {/* <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.7.0"
              versionUrl="https://github.com/hudl/rn-uniform/releases"
            />
            <div className={styles.changesList}>
              <Added>
                Export the <code>Icon</code> component to use with custom SVGs. This already exists in the web library.
              </Added>
            </div>
          </div> */}
          {/* <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />
            <div className={styles.changesList}>
              <Fixed>
                Sample change for <Link href="/components/modules/code">sample link</Link>.
              </Fixed>
            </div>
          </div> */}
          {/* <div className={styles.platform}>
            <PlatformHeader libName="Storybook" />
            <div className={styles.changesList}>
              <Removed>
                Sample change for <Link href="/components/modules/code">sample link</Link>.
              </Removed>
            </div>
          </div> */}
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
